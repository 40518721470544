import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				OceanWave Surf Academy
			</title>
			<meta name={"description"} content={"Akademimiz sizi okyanusun ritmini kucaklamaya ve sörfün heyecanını keşfetmeye davet ediyor."} />
			<meta property={"og:title"} content={"OceanWave Surf Academy"} />
			<meta property={"og:description"} content={"Akademimiz sizi okyanusun ritmini kucaklamaya ve sörfün heyecanını keşfetmeye davet ediyor."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="box4" />
			<Override slot="box5" />
		</Components.Header2>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box margin="-16px -16px -16px -16px" padding="16px 16px 16px 16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box
						background="url(https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-2.jpg?v=2024-06-06T12:06:02.447Z) top/cover"
						padding="0px 0px 672px 0px"
						margin="0px -112px 0px 0px"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 400px 0px"
					/>
				</Box>
				<Box width="50%" display="flex" padding="16px 16px 0px 16px" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						mix-blend-mode="lighten"
						background="--color-primary"
						margin="36px 0px -20px -112px"
						display="flex"
						flex-direction="column"
						color="--light"
						lg-margin="0px 0px 0px 0px"
						lg-width="100%"
						sm-padding="64px 32px 64px 32px"
					>
						<Text as="h1" margin="0 0 16px 0" font="--headline1" lg-font="--headline2">
							Dalgaları Güvenle ve Tarzla Sürün
						</Text>
						<Text as="p" margin="16px 0" font="--lead" max-width="400px">
							OceanWave Sörf Akademisi'nde acemiden ileri seviyeye kadar her sörfçünün ihtiyaçlarını karşılamak için tasarlanmış kapsamlı bir hizmet yelpazesi sunuyoruz. Amacımız sadece dalgaları yakalamanın ötesine geçen eksiksiz bir sörf deneyimi sunmaktır.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 0 0" quarkly-title="Product-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="--headline2"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Eğitmenlerimizle Tanışın
			</Text>
			<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="center">
				OceanWave Sörf Akademisi'ndeki eğitmenlerimiz yetenekli sörfçülerden çok daha fazlasıdır; kendilerini sörf başarınıza adamış tutkulu eğitimcilerdir. Her biri benzersiz bir beceri seti ve spora kişisel bir bağlılık getirerek her seviyedeki sörfçüler için kişiselleştirilmiş ve etkili bir eğitim sağlar. Destekleyici ve güvenli bir öğrenme ortamı yaratmada başarılıdırlar; her öğrenciyi okyanusun gücüne saygı duyarak ellerinden gelenin en iyisini yapmaya motive ederler. İster ilk dalganızı yakalıyor olun, ister ileri düzey tekniklerde uzmanlaşıyor olun, eğitmenlerimiz yolun her adımında yanınızdadır.
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				font="--headline2"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Eğitmenlerimizle Tanışın
			</Text>
			<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="center">
				Tekliflerimiz, sörf yolculuğunuzu geliştirmek için özel olarak tasarlanmış olup deneyiminizin her yönünün kapsanmasını sağlar:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12:06:02.435Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Özelleştirilmiş Eğitim
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="left">
						Her oturum beceri seviyenize ve sörf hedeflerinize göre özelleştirilir.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12:06:02.480Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Bire Bir Odaklanma
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="left">
						Sertifikalı eğitmenlerimizin kişisel ilgisi, daha hızlı ve daha güvenli ilerlemenize yardımcı olur.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-3.jpg?v=2024-06-06T12:06:02.416Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-3.jpg?v=2024-06-06T12%3A06%3A02.416Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-3.jpg?v=2024-06-06T12%3A06%3A02.416Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-3.jpg?v=2024-06-06T12%3A06%3A02.416Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-3.jpg?v=2024-06-06T12%3A06%3A02.416Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-3.jpg?v=2024-06-06T12%3A06%3A02.416Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-3.jpg?v=2024-06-06T12%3A06%3A02.416Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-3.jpg?v=2024-06-06T12%3A06%3A02.416Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Video Analizi
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="left">
						Gelişmiş koçluk teknikleri, tekniğinizi geliştirmek için video geri bildirimi içerir.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-3">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-2.jpg?v=2024-06-06T12:06:02.401Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-2.jpg?v=2024-06-06T12%3A06%3A02.401Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-2.jpg?v=2024-06-06T12%3A06%3A02.401Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-2.jpg?v=2024-06-06T12%3A06%3A02.401Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-2.jpg?v=2024-06-06T12%3A06%3A02.401Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-2.jpg?v=2024-06-06T12%3A06%3A02.401Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-2.jpg?v=2024-06-06T12%3A06%3A02.401Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-2.jpg?v=2024-06-06T12%3A06%3A02.401Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Grup Oturumları
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="left">
						Toplulukla Öğrenme: Dalgaları paylaşın ve destekleyici bir grup ortamında birlikte öğrenin.{"\n"}
						<br />
						Aile ve Arkadaş Paketleri: Gruplara özel fiyatlar herkesin eğlenceye katılmasını sağlar.{"\n"}
						<br />
						Kurumsal Etkinlikler: Dalgaların üzerinde bir gün geçirerek takım ruhu oluşturun.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12:06:02.397Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Ekipman kiralama
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="left">
						Yüksek Kaliteli Ekipman: En üst düzey sörf tahtalarımızı, wetsuitlerimizi ve diğer ekipmanlarımızı kullanın.{"\n"}
						<br />
						Sorun Yok: Plaja gitmek için ihtiyacınız olan her şey hazır.{"\n"}
						<br />
						Esnek Kiralama Süreleri: Programınıza uyacak şekilde saate, güne veya haftaya göre kiralayın.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12:06:02.422Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Gelişmiş Klinikler
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="left">
						Teknik Geliştirme: Kürek çekme, dönme ve zamanlama gibi belirli becerilere odaklanan atölyeler.{"\n"}
						<br />
						Profesyonel Görüşler: Dalga dinamiğinin nüanslarını anlayan uzmanlardan bilgi edinin.{"\n"}
						<br />
						Rekabet Avantajı: Hedefli eğitimle sörf yarışmalarına hazırlanın.
					</Text>
				</Box>
			</Box>
			<Button
				padding="14px 34px 14px 34px"
				background="--color-primary"
				font="--lead"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
			>
				Kişiler
			</Button>
		</Section>
		<Section padding="0 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 1rem 0px" md-margin="0px 0px 30px 0px" />
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 1rem 0px" color="--darkL2" font="--base" md-text-align="center">
						Özel sezonluk sörf inzivalarımız aracılığıyla OceanWave Sörf Akademisi ile unutulmaz bir yolculuğa çıkın. Bu özel etkinlikler sizi sörf yaşam tarzına derinlemesine dahil etmek, yeni plajları keşfetmenize ve farklı dalga koşulları altında becerilerinizi geliştirmenize olanak sağlamak için tasarlandı. İnzivalarımız, sörf becerilerinizi geliştirmek ve okyanus deneyiminizi zenginleştirmek için tasarlanmış, yoğun eğitim, dinlenme ve maceranın benzersiz bir karışımını sunar. İster yaz güneşinin altında sörf yapmanın heyecanını ister sessiz sonbahar dalgalarının dinginliğini arayın, inziva yerlerimiz denizle uyum içinde ruhunuzu ve becerilerinizi yükseltmek için mükemmel bir kaçamak sağlar.
					</Text>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="40% 0px 0px 0px"
						border-radius="24px"
						md-margin="0px 0px 20px 0px"
					>
						<Image
							src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
							object-position="center"
							srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12%3A06%3A02.413Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12%3A06%3A02.413Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12%3A06%3A02.413Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12%3A06%3A02.413Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12%3A06%3A02.413Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12%3A06%3A02.413Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12%3A06%3A02.413Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="40% 0px 0px 0px"
						border-radius="24px"
						margin="0px 0px 40px 0px"
						md-margin="0px 0px 20px 0px"
					>
						<Image
							src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-4.jpg?v=2024-06-06T12:06:02.388Z"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
							srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-4.jpg?v=2024-06-06T12%3A06%3A02.388Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-4.jpg?v=2024-06-06T12%3A06%3A02.388Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-4.jpg?v=2024-06-06T12%3A06%3A02.388Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-4.jpg?v=2024-06-06T12%3A06%3A02.388Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-4.jpg?v=2024-06-06T12%3A06%3A02.388Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-4.jpg?v=2024-06-06T12%3A06%3A02.388Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-4.jpg?v=2024-06-06T12%3A06%3A02.388Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--lead" md-text-align="center">
						Bu genel bakış, OceanWave Surf Academy'nin sunduğu şeylerin sadece başlangıcıdır. Tüm hizmet yelpazemiz, seviyeleri ne olursa olsun her sörfçünün mükemmelleşmek için ihtiyaç duyduğu desteği ve fırsatları bulmasını sağlayacak şekilde tasarlanmıştır. Tekliflerimizin tamamını keşfetmek için bizimle iletişime geçerek veya bizzat ziyaret ederek dünyamızın derinliklerine dalın.
					</Text>
					<Button
						type="link"
						href="/contacts"
						text-align="center"
						text-decoration-line="initial"
						margin="2rem 0px 0px 0px"
					>
						Kişiler
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link3">
				Kişiler
			</Override>
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65d5d38ffdcae00021def28a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});